$white: #fff;
$lighter-grey: #f0f0f0;
$light-grey: #e6e6e6;
$medium-light-grey: #999;
$medium-grey: #555;
$dark-grey: #333;
$dark-highlight: #38f;
$light-highlight: #e9f4f7;

$border-radius: 4px;
$box-shadow: 6px 8px 42px 1px rgba(0, 0, 0, .15);

body {
  margin: 0;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-grey;
}