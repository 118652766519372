@import '../../App.scss';

@mixin calculator-row {
    display: flex;
    justify-content: space-between;
    padding: 13px 0 13px 13px;
    border-bottom: 1px solid $light-grey;
    background-color: $white;

    &__label {
        color: $medium-grey;
    }

    &__input {
        flex-basis: 180px;
    }

    &__separator,
    &__value-label {
        float: left;
        color: $dark-grey;
    }

    &__separator {
        margin: 0 1px;
    }

    &__value-label {
        margin: 0 7px 0 4px;
    }

    &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

.calculator {
    width: 280px;
    margin: 40px auto;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 13px;
        background-color: $light-grey;
        color: $medium-light-grey;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &__header div {
        margin-top: 2px;
    }

    &__header button {
        padding: 4px;
        color: $medium-light-grey;
        border: 0;
        border-radius: 3px;
        background-color: $lighter-grey;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:active,
        &:hover {
            color: $dark-highlight;
            background-color: $light-highlight;
        }
    }
}
